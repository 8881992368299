<template>
  <div class="header-container" style="width: 100%;">
    <h1>Austin Gentry</h1>
    <h3>Developer</h3>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
.header-container {
  padding-bottom: 30px;
}

.header-container h1 {
  font-size: 70pt;
  margin-block-end: 0px;
}
</style>
