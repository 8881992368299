<template>
  <div class="nav">
    <div class="nav-bar">
<!--      <router-link to="/photos" class="nav-item">PHOTOS</router-link>-->
<!--      <router-link to="/design" class="nav-item">DESIGN</router-link>-->
      <router-link to="/blog" class="nav-item">BLOG</router-link>
      <router-link to="/" class="nav-item">PROJECTS</router-link>
      <a class="nav-item" href="https://github.com/gnitsua">GITHUB</a>
<!--      <a class="nav-item" href="https://shop.spreadshirt.com/BassmonkeysInc/">SHIRTS</a>-->
      <router-link to="/about" class="nav-item">ABOUT</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar"
}
</script>

<style scoped>
.nav {
  padding-bottom: 30px;
}

.nav-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
}


.nav-item {
  padding: 20px;
}

.nav a {
  color: black;
  text-decoration: none;
}

.nav a:hover {
  color: var(--accent);
}
</style>
