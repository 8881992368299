<template>

  <div class="post-preview">
    <router-link :to="`/blog/${postName}`" class="preview-image" tag="a">
      <img style="width: 100%; height: 100%;" alt="Blog Image" :src="require(`../assets/preview_images/${image}`)">
    </router-link>
    <div class="body">
      <div class="preview-label">Blog Post</div>
      <div class="post-preview-header">
        <h3 class="post-title">{{ title }}</h3>
        <div class="post-date">Austin Gentry - {{ date }}</div>
      </div>
      <div class="content">
        {{ body }}
      </div>
      <router-link :to="`/blog/${postName}`" class="read-more-link" tag="div">
        READ MORE
      </router-link>

    </div>
  </div>
</template>

<script>
export default {
  name: "PostPreview",
  props: {
    title: String,
    date: String,
    image: String,
    body: String,
    postName: String,
  }
}
</script>

<style scoped>
.post-preview {
  display: flex;
  align-items: start;
  justify-content: left;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 1000px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: solid 1px #e3e3e3;
}

.preview-label {
  color: var(--accent);
  font-weight: bold;
}

.preview-image {
  width: 30%;
}

.body {
  text-align: left;
  width: 70%;
  padding-left: 30px;
}

.content {
  padding-top: 10px;
}

.read-more-link {
  width: 130px;
  height: 25px;
  border: solid 2px var(--accent);
  color: var(--accent);
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
  padding-top: 9px;
  cursor: pointer;
}

.read-more-link:hover {
  opacity: 0.80;
}
</style>
