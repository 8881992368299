<template>
  <div class="about-page">
    <div class="about-image">
      <img :src="require(`../assets/austin.jpg`)" alt="austin">
      <h1>About Me</h1>
      <div class="about-body">
        Hi there, I’m Austin
        <br>
        <br>
        I’m a developer currently working at Amazon AWS. I have an MS in Computer Science and a BS in Mechanical
        Engineering, both from Drexel University.
        <br>
        <br>
        I’m a bass player, photographer, designer, and cyclist. My favorite thing to do is to create, and I jump at any
        opportunity to take on a new challenge.
        <br>
        <br>
        <a href="AustinGentryResume.pdf" target="_blank">Click here for a link to my resume.</a>
        <br><br>
        Also checkout out my <a href="https://github.com/gnitsua">Github</a> and connect with me on <a href="https://www.linkedin.com/in/gnitsua/">LinkedIn</a>.

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>

</style>
