<template>
  <div class="error-page">
    <h1>Page Not Found</h1>
    <p>This Page might still be under construction, please check back later</p>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>

</style>
