<template>
  <div class="post">
<!--    <div class="post-header">-->
<!--      <h3 class="post-title">{{ title }}</h3>-->
<!--      <div class="post-date"> {{ date }}</div>-->
<!--    </div>-->
    <div class="post-body">
      <div v-html="post" class="markdown-body" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { marked } from "marked";

export default {
  name: "Post",
  props: {
    title: String,
    date: String,
  },
  data() {
    return {
      post: "Fetching"
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      axios.get(`/posts/${this.$route.params.id}/post.md`)
          .then(response => {
            this.post = marked(response.data)
          })
          .catch(error => {
            console.log(error)
            this.post = "Something went wrong..."
          })
    }
  }
}
</script>

<style scoped>
/*.post {*/
/*  padding-top: 30px;*/
/*}*/

/*.post-date {*/
/*  padding-top: 10px;*/
/*  font-size: 15px;*/
/*  color: var(--grey);*/
/*  font-family: Georgia;*/
/*  font-style: italic;*/
/*}*/

.post-body {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.markdown-body {
  color: black;
  font-size: 15px;
  line-height: 25px;
}

.markdown-body >>> h1 {
  font-size: 40px;
  display: block;
  text-align: center;
  border-bottom: none;
}

.markdown-body >>> img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
}
</style>
