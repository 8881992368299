<template>
  <div id="app">
    <Header/>
    <NavBar/>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from './components/Header'
import NavBar from "@/components/NavBar";

export default {
  name: 'App',
  components: {
    NavBar,
    Header
  }
}
</script>

<style>
:root {
  --grey: rgb(161, 161, 161);
  --accent: rgb(202, 155, 82);
}

body {
  margin: 0px;
}

#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding-bottom: 30px; /* Temporary footer */
}

h1 {
  font-family: Georgia, serif;
}

h3 {
  font-family: Georgia, serif;
  font-size: 15pt;
  margin-block: 0px;
}

.post-title {
  font-size: 40px;
}

.post-date {
  padding-top: 10px;
  font-size: 15px;
  color: var(--grey);
  font-family: Georgia, serif;
  font-style: italic;
}

a {
  color: var(--accent);
  text-decoration: none;
}
</style>
