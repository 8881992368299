<template>
  <div class="post-list">
    <PostPreview v-for="post in posts"
                 :key="post.title"
                 :title=post.title
                 :date=post.date
                 :image=post.image
                 :body=post.body
                 :post-name=post.postName
    >
    </PostPreview>
  </div>
</template>

<script>
import PostPreview from "@/components/PostPreview";

export default {
  name: "PostList",
  components: { PostPreview },
  data() {
    return {
      posts: [
        {
          title: "YOLOv8 for counting train cars",
          date: "Nov 11, 2023",
          image: 'tracking_works.png',
          body: 'Have you ever sat at a railroad crossing for seemed like forever and wondered how many cars went past? Have you ever tried to count them and lost interest at 97? Well let\'s try to use computer vision to make up for our short attention spans!',
          postName: "train_counter"
        },
          {
          title: "Musings on Unit Tests in Java",
          date: "Nov 3, 2023",
          image: 'unit_tests.png',
          body: 'Unit tests are often the most difficult part of a codebase to read, yet developers often spend the least time thinking of best practice for making readable tests. Let\'s define some basic structures that make tests easier to read',
          postName: "unit_testing"
        },
        {
          title: "Solving Google Hashcode in one hour",
          date: "April 1st, 2022",
          image: 'hashcode_results.png',
          body: 'I have participated in the Google Hashcode competition for the last four years. I\'ve learned a lot along the way.',
          postName: "hashcode"
        },
        {
          title: "StatReloader loop with Django and Pycharm",
          date: "May 1, 2019",
          image: 'django_reloaded.jpg',
          body: 'I came across a strange issue the other day while working on a remote server using PyCharm. Occasionally when I uploaded a file (using the deployment options) Django would enter a loop and continuously reload the file (crashing the server).',
          postName: "django_reloaded"
        },
        {
          title: "MySQL on AWS",
          date: "March 7, 2019",
          image: 'aws_sql.png',
          body: 'For anyone who just needs a quickly accessible MySQL instance, AWS is a good (and hopefully free option).',
          postName: "mysql"
        },
        {
          title: "An Architecture for Scientific Collaboration",
          date: "Feb 1, 2019",
          image: 'architecture_talk.jpg',
          body: 'This is a talk I gave at the AWRA talk in April 2018. For this talk I was asked to discuss the methods that I had developed with the Drexel Environmental Data Science group to create APIs from scientific algorithms. I’ll include the slides here, as well as my best summary of what I said.',
          postName: "scientific_collaboration"
        },
        {
          title: "Docker Network Settings",
          date: "Nov 7, 2018",
          image: 'docker_network.png',
          body: 'Seems like Docker never stops throwing me new challenges. The other day I was deploying on a cloud provider, and my container that worked perfectly fine on my local machine refused to build.',
          postName: "docker_network"
        },
        {
          title: "Timeit decorator for Python",
          date: "October 12, 2018",
          image: 'timeit.png',
          body: 'Frequently I work on projects where I need to optimize the performance of some python functions. While Pycharm has an excellent integration of cProfiler built into the application, I decided that I should write a simple decorator that I could place on various functions to quickly test their performance.',
          postName: "timeit_decorator"
        },
        {
          title: "Chess Move Validator",
          date: "October 2, 2018",
          image: 'chess_checker.png',
          body: 'The repository contains a valid move generator designed to follow the following input and output scheme.The goal of this project was not performance but rather to be highly “dependable”. To do this, move validation was done using a set subtraction system wherein each piece’s valid moves are a subset of every possible valid move.',
          postName: "chess_checker"
        },
        {
          title: "Parsing Baseball data with Luigi",
          date: "April 11, 2019",
          image: 'baseball.png',
          body: 'For a project recently I needed create a large dataset of information on baseball games. Luckily, MLB is one of the most stats-heavy professional sports, and there is no shortage of good resources.',
          postName: "baseball"
        },
        {
          title: "Controlling Neopixels with Plan9",
          date: "August 7, 2018",
          image: 'neo-pixels.gif',
          body: 'I managed to get NeoPixels to work as a user space program in Plan 9.Things I learned:\n– NeoPixels seem to do fine on 3.3V\n– The SPI interface in Plan 9 does not have reliable enough timing to eliminate occasional flickering\n',
          postName: "neopixel"
        },
        {
          title: "Lighted Bed for the M3D Micro",
          date: "May 3, 2018",
          image: 'm3d.gif',
          body: 'I Love the M3D Micro. I’ve been using it for over a year now, and while it’s not the most capable printer out there it does almost everything I could want. One of the first things I felt it was missing however was good lighting so I could watch it print.',
          postName: "lighted_printer"
        },
      ]
    };
  }
}
</script>

<style scoped>

</style>
