import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueSimpleMarkdown from 'vue-simple-markdown'
import 'vue-simple-markdown/dist/vue-simple-markdown.css'
import Post from "@/components/Post";
import PostList from "@/components/PostList";
import PageNotFound from "@/components/PageNotFound";
import About from "@/components/About";

Vue.config.productionTip = false

Vue.use(VueSimpleMarkdown)
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/', alias: ['/blog/'], component: PostList, props: {} }, // Also allow /blog with no post id to route to the index
    { path: '/blog/:id', component: Post, props: {title: "Test Title", date:"12/12/12", body:"test"} },
    { path: '/about', component: About, props: {} },
    { path: "*", component: PageNotFound }
  ]
})

export default new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
